<template>
  <v-container v-if="annotation">
    <!-- Annotation Type -->
    <v-row>
      <v-col cols="12" md="3"><strong>Type:</strong></v-col>
      <v-col cols="12" md="9">
        {{ annotationType }}
      </v-col>
    </v-row>

    <!-- Annotation Fill -->
    <v-row>
      <v-container>
        <expansion-panels-layout :inset="true">
          <!-- header -->
          <template v-slot:header>
            Fill &emsp;
            <core-chip-group
              :items="fillOptionsList"
              :column="true"
              :show-arrows="true"
            >
            </core-chip-group>
          </template>
          <template v-slot:content>
            <!-- Color -->
            <v-subheader class="pl-0">
              Color
            </v-subheader>
            <v-color-picker
              v-model="selectedColor"
              @update:color="fillColorChanged"
            ></v-color-picker>

            <!-- Opacity -->
            <v-subheader class="pl-0">
              Opacity
            </v-subheader>
            <v-slider
              v-model="opacity"
              :max="maxOpacity"
              :min="minOpacity"
              :thumb-size="20"
              thumb-label="always"
              hide-details
              @input="fillOpacityChanged"
            >
            </v-slider>
          </template>
        </expansion-panels-layout>
      </v-container>
    </v-row>

    <!-- Annotation Size -->
    <v-row>
      <v-col cols="12" md="3"><strong>Size:</strong></v-col>
      <v-col cols="12" md="9">
        <v-row dense>
          <v-col cols="12">
            {{ annotationHeightLabel }}
          </v-col>
          <v-col cols="12">
            {{ annotationWidthLabel }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Annotation Outline-->
    <v-row>
      <v-container>
        <expansion-panels-layout :inset="true">
          <!-- header -->
          <template v-slot:header>
            Outline &emsp;
            <core-chip-group
              :items="outlineOptionsList"
              :column="true"
              :show-arrows="true"
            >
            </core-chip-group>
          </template>
          <template v-slot:content>
            <!-- Color -->
            <v-subheader class="pl-0">
              Color
            </v-subheader>
            <v-color-picker
              v-model="selectedOutlineColor"
              @update:color="outlineColorChanged"
            ></v-color-picker>

            <!-- Opacity -->
            <v-subheader class="pl-0">
              Opacity
            </v-subheader>
            <v-slider
              v-model="outlineOpacity"
              :max="maxOpacity"
              :min="minOpacity"
              :thumb-size="20"
              thumb-label="always"
              hide-details
              @input="outlineOpacityChanged"
            >
            </v-slider>

            <!-- Width -->
            <v-subheader class="pl-0">
              Width
            </v-subheader>
            <v-slider
              v-model="outlineWidth"
              :max="maxWidth"
              :min="minWidth"
              :thumb-size="20"
              thumb-label="always"
              hide-details
              @input="outlineWidthChanged"
            >
            </v-slider>
          </template>
        </expansion-panels-layout>
      </v-container>
    </v-row>

    <!-- Annotation Text Value -->
    <v-row v-show="isTextAnnotation">
      <v-col cols="12" md="3"><strong>Text:</strong></v-col>
      <v-col cols="12" md="9">
        <v-text-field
          v-model="annotationText"
          dense
          @change="onTextChanged"
        ></v-text-field>
      </v-col>
    </v-row>

    <!-- Rotation -->
    <v-row>
      <v-col cols="12" md="3"><strong>Rotation:</strong></v-col>
      <v-col cols="12" md="9">
        <v-text-field
          v-model="annotationRotation"
          dense
          @change="onRotationChanged"
        ></v-text-field>
      </v-col>
    </v-row>

    <!-- Annotation Text/Font-->
    <v-row v-show="isTextAnnotation">
      <v-container>
        <expansion-panels-layout :inset="true">
          <!-- header -->
          <template v-slot:header>
            Font &emsp;
            <core-chip-group
              :items="textOptionsList"
              :column="true"
              :show-arrows="true"
            >
            </core-chip-group>
          </template>
          <template v-slot:content>
            <!-- Color -->
            <v-subheader class="pl-0">
              Color
            </v-subheader>
            <v-color-picker
              v-model="selectedFontColor"
              @update:color="fontColorChanged"
            ></v-color-picker>

            <!-- Bold -->
            <v-checkbox
              v-model="fontBold"
              label="Bold"
              @change="fontBoldChanged"
            ></v-checkbox>

            <!-- Size-->
            <v-subheader class="pl-0">
              Size
            </v-subheader>
            <v-slider
              v-model="fontSize"
              :max="maxFontSize"
              :min="minFontSize"
              :thumb-size="20"
              thumb-label="always"
              hide-details
              @input="fontSizeChanged"
            >
            </v-slider>

            <!-- Italic -->
            <v-checkbox
              v-model="fontItalic"
              label="Italic"
              @change="fontItalicChanged"
            ></v-checkbox>

            <!-- Underline -->
            <v-checkbox
              v-model="fontUnderline"
              label="Underline"
              @change="fontUnderlineChanged"
            ></v-checkbox>
          </template>
        </expansion-panels-layout>
      </v-container>
    </v-row>

    <!-- Annotation Location -->
    <v-row>
      <v-col cols="12" md="3"><strong>Location:</strong></v-col>
      <v-col cols="12" md="9">
        <v-row dense>
          <v-col cols="12">
            {{ annotationHorizontalPosition }}
          </v-col>
          <v-col cols="12">
            {{ annotationVerticalPosition }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { annotationColors } from "@/model/documentViewer/annotationControllerModel";

export default {
  name: "AnnotationEditor",
  components: {
    ExpansionPanelsLayout: () =>
      import("@/components/shared/core/expansionPanel/ExpansionPanelsLayout"),
    CoreChipGroup: () =>
      import("@/components/shared/core/chipGroup/CoreChipGroup")
  },
  props: {
    annotation: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      selectedColor: this.annotation?.fill?.color,
      selectedOutlineColor: this.annotation?.outline?.color,
      selectedFontColor: this.selectedAnnotation?.text?.font?.color,
      minOpacity: 0,
      maxOpacity: 10,
      opacity: 10,
      outlineOpacity: 10,
      outlineWidth: this.selectedAnnotation?.outline?.width,
      minWidth: 0,
      maxWidth: 100,
      annotationRotation: this.selectedAnnotation?.rotation ?? 0,
      fontBold: this.selectedAnnotation?.text?.font?.bold,
      fontSize: this.selectedAnnotation?.text?.font?.size,
      fontItalic: this.selectedAnnotation?.text?.font?.italic,
      fontUnderline: this.selectedAnnotation?.text?.font?.underline,
      maxFontSize: 500,
      minFontSize: 0,
      annotationText: this.selectedAnnotation?.text?.value,
      red: "red"
    };
  },
  computed: {
    annotationType() {
      return this.annotation?.type ?? "";
    },
    selectedAnnotation() {
      return this.annotation;
    },
    fillOptionsList() {
      return ["Color", "Opacity"];
    },
    outlineOptionsList() {
      return ["Color", "Opacity", "Width"];
    },
    textOptionsList() {
      return ["Color", "Size"];
    },
    annotationHeightLabel() {
      return `Height: ${this.selectedAnnotation?.height}`;
    },
    annotationWidthLabel() {
      return `Width: ${this.selectedAnnotation?.width}`;
    },
    annotationHorizontalPosition() {
      return `X: ${this.selectedAnnotation?.x}`;
    },
    annotationVerticalPosition() {
      return ` Y: ${this.selectedAnnotation?.y}`;
    },
    isTextAnnotation() {
      return (
        this.selectedAnnotation?.type === "stamp" ||
        this.selectedAnnotation?.type === "text"
      );
    }
  },
  methods: {
    fillColorChanged(color) {
      if (color.hex.toLowerCase() === annotationColors[this.red]) return;
      this.selectedAnnotation.fill.color = color.hex.toLowerCase();
      this.selectedAnnotation.update();
    },
    fillOpacityChanged(opacity) {
      this.selectedAnnotation.fill.opacity = opacity / 10;
      this.selectedAnnotation.update();
    },

    outlineOpacityChanged(opacity) {
      this.selectedAnnotation.outline.opacity = opacity / 10;
      this.selectedAnnotation.update();
    },

    outlineColorChanged(color) {
      if (color.hex.toLowerCase() === annotationColors[this.red]) return;
      this.selectedAnnotation.outline.color = color.hex.toLowerCase();
      this.selectedAnnotation.update();
    },

    outlineWidthChanged(width) {
      this.selectedAnnotation.outline.width = width;
      this.selectedAnnotation.update();
    },

    onRotationChanged(rotation) {
      this.selectedAnnotation.rotation = rotation;
      this.selectedAnnotation.update();
    },
    fontColorChanged(color) {
      if (color.hex.toLowerCase() === annotationColors[this.red]) return;
      this.selectedAnnotation.text.font.color = color.hex.toLowerCase();
      this.selectedAnnotation.update();
    },
    fontBoldChanged() {
      this.selectedAnnotation.text.font.bold = this.fontBold;
      this.selectedAnnotation.update();
    },
    fontSizeChanged(size) {
      this.selectedAnnotation.text.font.size = size;
      this.selectedAnnotation.update();
    },
    fontItalicChanged() {
      this.selectedAnnotation.text.font.italic = this.fontItalic;
      this.selectedAnnotation.update();
    },
    fontUnderlineChanged() {
      this.selectedAnnotation.text.font.underline = this.fontUnderline;
      this.selectedAnnotation.update();
    },
    onTextChanged(value) {
      this.selectedAnnotation.text.value = value;
      this.selectedAnnotation.update();
    }
  },
  watch: {
    annotation: {
      handler() {
        if (this.annotation) {
          this.selectedColor = this.annotation?.fill?.color;
          this.selectedOutlineColor = this.annotation?.outline?.color;
          this.selectedFontColor = this.selectedAnnotation?.text?.font?.color;
          this.opacity = this.annotation?.fill?.opacity * 10;
          this.outlineOpacity = this.annotation?.outline?.opacity * 10;
          this.outlineWidth = this.selectedAnnotation?.outline?.width;
          this.annotationRotation = this.selectedAnnotation?.rotation ?? 0;
          this.fontBold = this.selectedAnnotation?.text?.font?.bold;
          this.fontSize = this.selectedAnnotation?.text?.font?.size;
          this.fontItalic = this.selectedAnnotation?.text?.font?.italic;
          this.fontUnderline = this.selectedAnnotation?.text?.font?.underline;
          this.annotationText = this.selectedAnnotation?.text?.value;
        }
      },
      deep: true
    }
  }
};
</script>

<style scoped></style>
